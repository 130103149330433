import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser, setUser, reset, selectReset } from '../../features/isUserAuthSlice';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import './Nav.css'

const BurgerIcon = styled.div`
    display: none;

    @media (max-width: 768px) {
      display: block;
      cursor: pointer;
      height: 0;
      font-size: 3em;
      position: relative;
      bottom: 0.3em;
      text-align: right;
    }
  `;

export default function Nav() {
  const [isOpen, setIsOpen] =  useState(false);
  const user = useSelector( selectUser );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const burger = isOpen ? '✖️' : '☰'
  const toggleCloseClass = isOpen ? 'open' : 'closed'
  
  // const reset = useSelector( selectReset );
  console.log( 'Nav - user --> ', user );

  const handleLogoClick = e => {
    e.preventDefault();

    if ( isOpen ) {
      setIsOpen(!isOpen);
    }
    
    navigate('/');
  }

  const handleLogout = e => {
    e.preventDefault();
    dispatch( reset() );
    localStorage.clear('hwuUser');
    navigate('/');
    setIsOpen(!isOpen);
  }

  return(
    <nav className='hwuMainWrap'>
      <div className="navWrap">

        <div className='hwuContentWrap'>
          
          <div className='hwuNav'>
            <div className="navLogoWrap">
              <Link onClick={ handleLogoClick }>
                <h1>heWitUs <span>🏀</span></h1>
              </Link>
            </div>

            <div className="navContentSection hwu-mfdoom">
              <ul>
                <li>
                  <Link to="/leagues">Leagues</Link>
                </li>
                <li>
                  <Link to="/store">Store</Link>
                </li>
                <li>
                  <Link to="/about">About</Link>
                </li>
              </ul>
            </div>

            <div className="navLoginSection hwu-mfdoom">
              { user ? (
                <>
                  <span>
                    <Link to="/profile">{ 'Profile' }</Link>
                  </span> / <span>
                    <Link onClick={ handleLogout } to="/">Log Out</Link>
                  </span>
                </>
                ) : (
                <>
                <span className="login">
                  <Link to="/login">Login</Link>
                </span> / <span className="signup">
                  <Link to="/signup">Sign Up</Link>
                </span>
                </>
              )}
            </div>

            <BurgerIcon onClick={() => setIsOpen(!isOpen)}>
              <div className={toggleCloseClass} id="menuIcon">
                <div className='menuBar'></div>
                <div className='menuBar'></div>
                <div className='menuBar'></div>
              </div>
            </BurgerIcon>
          </div>

        </div>
        
        

      </div>

      { isOpen && (
          <div className='mobileNavMenuList hwu-mfdoom'>
            <ul>
            { user && (
                  <li>
                    <Link onClick={() => setIsOpen(!isOpen)} to="/profile">
                      <span>Profile</span>
                    </Link>
                  </li>
                )
              }
              <li>
                <Link onClick={() => setIsOpen(!isOpen)} to="/leagues">Leagues</Link>
              </li>
              <li>
                <Link onClick={() => setIsOpen(!isOpen)} to="/store">Store</Link>
              </li>
              <li>
                <Link onClick={() => setIsOpen(!isOpen)} to="/about">About</Link>
              </li>
              { user ? (
                  <li>
                    <Link onClick={ handleLogout } to="/">Log Out</Link>
                  </li>
                ) : (
                  <li>
                    <Link onClick={() => setIsOpen(!isOpen)} to="/login">Login</Link>
                  </li>
                )
              }
            </ul>
          </div>
        )
      }

    </nav>
  )
}