import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import churchSquareImage from '../assets/church-square-snow.png'
import churchSquareMovie from '../assets/church-square-snow.mov'
import '../App.css';

export default function Main() {
  const localStorageUser = JSON.parse( localStorage.getItem('hwuUser') );

  let navigate = useNavigate();
  const routeChange = () => {
    const welcomeButton = document.getElementById('welcomeButton');
    navigate( welcomeButton.dataset.welcomeStatus );
  }

  return(
    <div>
      <video
        id='hwuBackgroundVideo'
        autoPlay
        playsInline
        preload='auto'
        loop
        muted
        type='video/mp4'
        poster={churchSquareImage}
        src={churchSquareMovie} />

      <div className='textOverlay'>
        <h6>A Basketball League App</h6>
        <h3>FOR THE CULTURE </h3>
        <button 
          id='welcomeButton'
          className='hwuSubmitButton'
          data-welcome-status={ localStorageUser ? ( '/profile' ) : ('/signup' ) }
          onClick={ routeChange }>
          {
            localStorageUser ? ( localStorageUser.username ) : ( 'Join Now' )
          }
          </button>
      </div>

        <div className='hwuMainContentWrap hwuRow'>
          <div className='hwuMainContent'>
            <div className='hwuMainContentTextWrap'>
              <p>
                <strong>heWitUs</strong> is a league/tournament app that allows players to keep track of their season's scheduled games, view their team's standings, communicate with teammates, coach, and the commissioner of their league, view team and individual stats, view leader boards, scouting reports, etc...
              </p>
            </div>
              {/* <button className='hwuSubmitButton'>
                <Link to='/street-ball-weather'>
                  Street Basketball
                </Link>
              </button> */}
          </div>
        </div>
    </div>
  )
}