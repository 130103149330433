import { Link } from 'react-router-dom';
import howNealFeel from '../../assets/how_neal_feel_hewitus_480p.mov';
import jacksonStGame from '../../assets/hoboken-jackson-st-game.jpg';
import styled from 'styled-components';
import './About.css';

const AboutPageHeader = styled.section`
  background-image: url(${jacksonStGame});
  padding: 160px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  /* filter: brightness(90%); */
  opacity: 0.6;
`;

export default function About() {
  return(
    <div className='aboutPageContentWrap hwuRow'>

      <div className='aboutPageHeaderWrap'>
        <AboutPageHeader />
        <div className='textOverlay'>
          <h3>ABOUT HEWITUS</h3>
        </div>
      </div>

      <div className='hwuMainContent'>
        <div className='hwuMainContentTextWrap'>
          <p>
            <strong>heWitUs</strong> is a league/team/tournament management platform emphasizing basketball and its culture over everything and everybody.
          </p>

          <p>
            Our mission is to become the premier location for basketball players of all levels to visit when they need information regarding the teams they participate in and also become the go-to place to get local basketball news.
          </p>
        </div>
      </div>

      <div className='hwuMainContent hwuVideoWrap'>
        <div className="hwuVideoWrapText hwuMainContentTextWrap">
          <p>
            But what is the meaning of <strong>"hewitus"</strong>? The video below is an excerpt from the How Neal Feel podcast, where host Neal Brennan, co-host Bianca Sia, and professional basketball player Blake Griffin break down the term.
          </p>
        </div>
      
        <video width='100%' height='auto' controls preload='metadata'>
          <source src={howNealFeel + '#t=0.1'} type='video/mp4' />
          Your browser does not support HTML video.
        </video>
      </div>

      <div className='hwuMainContent'>
        <div className='hwuMainContentTextWrap'>
          <p>
            Are you a basketball coach or a team captain? Do you run a baskeball league or team and are looking for custom software solutions to manage your organization? Are you a basketball enthusiast curious about what we do, have questions, or want to get involved in our movement? <Link to="/contact">We would love to hear from you!</Link>
          </p>
        </div>
      </div>
    </div>
  )
}