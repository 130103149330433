import { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser, setUser } from '../../features/isUserAuthSlice';
import './Login.css';

export default function Login() {
  const backendURL = process.env.NODE_ENV === 'development' ? 'http://localhost:4523' : 'https://hewitus-backend.onrender.com';

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.post(`${backendURL}/api/users/signin`, {
        email,
        password
      });

      console.log( 'signin res --> ', res.data );
      dispatch( setUser(res.data) );
      localStorage.setItem('hwuUser', JSON.stringify(res.data) );
      navigate('/');

    } catch (error) {
      console.log( error );
    }
  }

  return(
    <div className="loginSection">

      <div className="hwuWrap">
        
        <div className="loginContent">
          <div className="loginCard">

            <div className="loginCardContent">
              <div className='loginCardTitle'>
                <h2>Welcome</h2>
              </div>

              <div className="signupFormWrap">
                
                <form onSubmit={handleLogin}>
                  <input
                    id='loginEmail'
                    className='signupInput'
                    type='email'
                    placeholder='Email'
                    maxLength="30"
                    autoComplete="on"
                    value={email}
                    onChange={ (e) => setEmail(e.target.value)}
                    required
                  />

                  <br />

                  <input
                    id='signupPassword'
                    className='signupInput'
                    type='password'
                    placeholder='Password'
                    maxLength="30"
                    autoComplete="on"
                    value={password}
                    onChange={ (e) => setPassword(e.target.value)}
                    required
                  />

                  <span className='forgotPasswordWrap'>
                    <Link to='/signup'>
                      <p>Forgot your password?</p>
                    </Link>
                  </span>

                  <div>
                    <button className='hwuLightSubmitButton' type='submit'>Log In</button>
                  </div>

                  <div>
                    <Link to='/signup'>
                      <p>Don't have an account?</p>
                    </Link>
                  </div>

                </form>
              </div>

            </div>

          </div>
        </div>

      </div>

    </div>
  );
}